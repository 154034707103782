(function(){

  var $btn = $('.js-m-toggle');
  var $menu = $('.js-mobilenav')

  $btn.on('click', toggleMenu);

  $('.js-mnav').on('click', 'a', function(e){
    e.preventDefault();

    toggleMenu(e);

    var href = $(this).attr('href');
    var slug = href.split('/').pop();
    var target = $('.js-anchor[data-anchor="'+ slug +'"]');
    var delta = target.offset().top - 20;
    $('html, body').scrollTop(delta);
  })

  function toggleMenu(e){
    e.preventDefault();
    $menu.toggleClass('isOpen');
    $btn.toggleClass('isToggled');
    $('html').toggleClass('menuIsOpen');
  }

})();
