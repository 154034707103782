var slick = require('slick-carousel');

(function(){
  $('.js-hero__slider').on('init', function(){
    $('.hero').addClass('slickIsLoaded');
  });

  $('.js-hero__slider').slick({
    fade: true,
    nextArrow: '<div class="slick__arrow slick__arrow--next"></div>',
    prevArrow: '<div class="slick__arrow slick__arrow--prev"></div>'
  });

})();
