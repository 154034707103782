(function(){

  $('.js-nav').on('click', 'a', function(e){
    e.preventDefault();

    var href = $(this).attr('href');
    var slug = href.split('/').pop();
    var target = $('.js-anchor[data-anchor="'+ slug +'"]');
    var delta = target.offset().top - 100;
    $('html, body').animate({
      scrollTop: delta
    }, 500);
  })

})();
