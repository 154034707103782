// fetch instagram
    if ($('#instabox').length) {
        var $tag = $('#instabox').data('tag');
        if(typeof $tag != 'undefined') {
            $.ajax({
                type: "GET",
                url: Routing.generate('site_frontend_ajax_instagram_call'),
                async: true,
                data: {
                    'tag' : $tag
                },
                success: function (data) {
                    if ( data.status === "ok" ) {
                        $('#instabox').show('slow');

                        var container = $('#instafeed-container');

                        // create left insta wall
                        var left   = $.el('div', {'class' : 'insta-side'});
                        $.each(data.left, function (index, insta) {
                            left.append(
                                $.el('div', {'class' : 'insta-sm', 'style' : 'background-image: url(' + insta.images.standard_resolution.url + ')' })
                            );
                        });
                        container.append(left);

                        // create center insta wall
                        if( typeof data.center != 'undefined' && !(data.center instanceof Array)) {
                            var center = $.el('div', {'class' : 'insta-center', 'style' : 'background-image: url(' + data.center.images.standard_resolution.url + ')' });
                            container.append(center);
                        }

                        // create right insta wall
                        var right  = $.el('div', {'class' : 'insta-side'});
                        $.each(data.right, function (index, insta) {
                            right.append(
                                $.el('div', {'class' : 'insta-sm', 'style' : 'background-image: url(' + insta.images.standard_resolution.url + ')'})
                            );
                        });
                        container.append(right);
                    }
                }
            });
        }
    }
