var _ = require('lodash');

(function(){

  var waypoint = $('.js-waypoint');

  var wh = $(window).outerHeight();
  wh -= wh/10;

  $(document).on('scroll', _.throttle(function() {
    var scrollTop = $(document).scrollTop();
    waypoint.each(function(){
      var offset = $(this).offset().top;
      if(offset <= scrollTop + wh){
        $(this).addClass('isVisible');
      }
    });
  }, 100));



})();
